import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import RankingTable from "../components/rankingTable";
import Layout from "../components/layout";

export default function Template({
  data, pageContext// this prop will be injected by the GraphQL query below.
}) {
  
  return (
    <Layout>
      <h1>STANDINGS</h1>
      <RankingTable data={data} selectedGame={pageContext.game} gameList={pageContext.allGames}>
      </RankingTable>
    </Layout>
  );
}

Template.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

export const pageQuery = graphql`
  query($game: String!) {
    allTournamentsJson(filter: {game: {eq: $game}}) {
      edges {
        node {
          game
          season
          results {
            name
            points
            team
          }
        }
      }
    }
    country1:allStatisticsXlsxSheet1 {
      edges {
        node {
          Country
          Name
        }
      }
    }
    country2:allStatistics2XlsxSheet1 {
      edges {
        node {
          Country
          Name
        }
      }
    }
    flags:allFile(filter: {relativeDirectory: {eq: "flags"}}) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 80, height: 60) {
              src
            }
          }
        }
      }
    }
  }
`;